import { useContext } from 'react';
import { AppState } from '../contexts';
import AssetRenderer from './asset-renderer';

export default function AssetView() {
	const { state, setState } = useContext( AppState );

	if ( !state.assetView )
		return;

	const handleClose = e => {
		e.preventDefault();
		setState( oldState => ( {
			...oldState,
			assetView: null
		} ) );
	};

	return <div style={ {
		position: 'absolute',
		zIndex: 99999999,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0,0,0,0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	} }>
		<div style={ {
			backgroundColor: 'white',
			border: '1px solid gray',
			borderRadius: '10px',
			padding: '32px',
			zIndex: 99999999
		}}>
			<button onClick={ handleClose }>X</button>
			<AssetRenderer
				workspace={ state.workspace }
				asset={ state.assetView } />
		</div>
	</div>;
}
