export function bufferToBase64( buffer ) {
	if ( buffer instanceof ArrayBuffer )
		buffer = new Uint8Array( buffer );

	return btoa( buffer.reduce( ( a, c ) => {
		a.push( String.fromCodePoint( c ) );
		return a;
	}, [] ).join( '' ) );
}

export function base64ToBuffer( ascii ) {
	let str = atob( ascii );
	let buffer = new Uint8Array( str.length );
	for ( let i = 0; i < str.length; i++ )
		buffer[ i ] = str[ i ].codePointAt( 0 );
	return buffer;
}
