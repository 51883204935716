import { createContext, useCallback, useState } from 'react';

export const AppState = createContext();
export const ApiClient = createContext();

const DEFAULT_GLOBAL_STATE = {
	page: 'login',
	pageParams: {},
	user: {
		loggedIn: false,
		email: ''
	},
	workspaces: [],
	workspace: {}
};

export function AppStateProvider( { children } ) {
	const [ state, setState ] = useState( DEFAULT_GLOBAL_STATE );

	const setUser = useCallback( user => {
		setState( oldState => ( {
			...oldState,
			user: {
				...oldState.user,
				...user
			}
		} ) );
	}, [ setState ] );
	const navigateTo = useCallback( ( page, params = {} ) => {
		setState( oldState => ( {
			...oldState,
			page,
			pageParams: params
		} ) );
	}, [ setState ] );

	const setWorkspaces = useCallback( workspaces => {
		setState( oldState => ( {
			...oldState,
			workspaces
		} ) );
	}, [ setState ] );

	const setWorkspace = useCallback( workspace => {
		setState( oldState => ( {
			...oldState,
			workspace
		} ) );
	}, [ setState ] );

	const pushAsset = useCallback( asset => {
		setState( oldState => ( {
			...oldState,
			workspace: {
				...oldState.workspace,
				assets: [ ...oldState.workspace.assets, asset ],
				assetsById: {
					...oldState.workspace.assetsById,
					[ asset.id ]: asset
				},
				assetsByType: {
					...oldState.workspace.assetsByType,
					[ asset.type ]: [
						...(oldState.workspace.assetsByType[ asset.type ] || []),
						asset
					]
				}
			}
		} ) );
	}, [ setState ] );

	const rerender = useCallback( asset => {
		setState( oldState => ( { ...oldState } ) ); // Just trigger a re-render. The asset is already updated.
	} );

	window.globalState = state;

	return <AppState.Provider value={ { state, setState, setUser, navigateTo, setWorkspaces, setWorkspace, rerender, pushAsset } }>
		{ children }
	</AppState.Provider>;
}
