import { useCallback, useContext, useState } from 'react';
import { ApiClient, AppState } from '../contexts';
import RichTextEditorComponent from './richt-text-editor-component';

function PlanRenderer( { workspace, plan } ) {
	const api = useContext( ApiClient );
	const { state, setState } = useContext( AppState );

	const handleSave = useCallback( data => {
		plan.content = data;
		api.savePlan( workspace, plan );
	}, [ api, workspace, plan ] )

	const handleClickAsset = useCallback( assetId => {
		let asset = state.workspace.assetsById[ assetId ]
		if ( !asset )
			return;
		setState( oldState => ( {
			...oldState,
			assetView: asset
		} ) );
	}, [ state, setState ] );

	return <div>
		<h2>{ plan.name }</h2>
		<RichTextEditorComponent
			onSave={ handleSave }
			onClickAsset={ handleClickAsset }
			plan={ plan } />
	</div>;
}

export default function PlanComponent() {
	const { state } = useContext( AppState );
	const [ chosenPlan, setChosenPlan ] = useState( state.workspace.plans[ 0 ] );

	const handlePlanSelection = useCallback( e => {
		e.preventDefault();
		let plan = state.workspace.plans.find( p => p._id === e.target.value );
		setChosenPlan( plan );
	}, [ state, setChosenPlan ] );

	return <div>
		<label htmlFor="plan_selector">Plan:</label>
		<select id="plan_selector" onChange={ handlePlanSelection }>
			{
				state.workspace.plans.map( plan =>
					<option key={ plan._id } value={ plan._id }>{ plan.name }</option> )
			}
		</select>
		<div>
			{ chosenPlan && <PlanRenderer workspace={ state.workspace } plan={ chosenPlan } /> }
		</div>
	</div>
}
