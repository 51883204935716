export default class Asset {
	static empty() {
		let asset = new this( {
			type: this.typeCode(),
			summary: '',
			content: {}
		} );
		let descriptors = Object.getOwnPropertyDescriptors( this.prototype );

		Object.getOwnPropertyNames( descriptors ).forEach( name => {
			if ( descriptors[ name ].set )
				asset[ name ] = '';
		} );

		return asset;
	}

	static fromJson( json ) {
		let AssetClass = ASSET_MAPPING[ json.type ] || Asset;
		return new AssetClass( json );
	}

	static allAssetTypes() {
		return ASSET_MAPPING;
	}

	static typeCode() {
		return Object.getOwnPropertyNames( this.allAssetTypes() ).find( code =>
			this.allAssetTypes()[ code ] === this );
	}

	static typeName() {
		return 'Asset';
	}

	constructor( { _id, type, summary, content } ) {
		this.id = _id;
		this.type = type;
		this.summary = summary;
		this.content = content;
	}

	get typeName() {
		return this.constructor.typeName();
	}

	updateContent( content ) {
		this.content = content;
		this.summary = this.generateSummary();
	}

	generateSummary() {
		return this.summary;
	}
}

class CreditCard extends Asset {
	static typeName() { return 'Credit Card'; }

	get network() { return this.content.network; }
	get number() { return this.content.number; }
	get name() { return this.content.name; }
	get expiration() { return this.content.expiration; }
	get cvc() { return this.content.cvc; }

	set network( v ) { this.content.network = v; }
	set number( v ) { this.content.number = v; }
	set name( v ) { this.content.name = v; }
	set expiration( v ) { this.content.expiration = v; }
	set cvc( v ) { this.content.cvc = v; }

	generateSummary() {
		let lastNumbers = /-(\d+)$/.exec( this.number )[ 1 ];
		return `${this.network} ${lastNumbers}`;
	}
}

class SavingsBankAccount extends Asset {
	static typeName() { return 'Savings Bank Account'; }

	get bank() { return this.content.bank; }
	get branch() { return this.content.branch; }
	get number() { return this.content.number; }
	get owner() { return this.content.owner; }

	set bank( v ) { this.content.bank = v; }
	set branch( v ) { this.content.branch = v; }
	set number( v ) { this.content.number = v; }
	set owner( v ) { this.content.owner = v; }

	generateSummary() {
		let lastDigits = this.number.slice( -4 );
		if ( lastDigits.length > 0 )
			return `${this.bank} #${lastDigits}`;
		return `${this.bank} savings account`;
	}
}

class Loan extends Asset {
	static typeName() { return 'Loan'; }

	get loanType() { return this.content.loan_type; }
	get totalAmount() { return this.content.total_amount; }

	set loanType( v ) { this.content.loan_type = v; }
	set totalAmount( v ) { this.content.total_amount = v; }

	generateSummary() {
		return `${this.loanType} Loan`;
	}
}

class SiteCredentials extends Asset {
	static typeName() { return 'Site Credentials'; }

	get site() { return this.content.site; }
	get url() { return this.content.url; }
	get username() { return this.content.username; }
	get email() { return this.content.email; }
	get password() { return this.content.password; }

	set site( v ) { this.content.site = v; }
	set url( v ) { this.content.url = v; }
	set username( v ) { this.content.username = v; }
	set email( v ) { this.content.email = v; }
	set password( v ) { this.content.password = v; }

	generateSummary() {
		return `${this.site} Credentials`;
	}
}

class BrokerAccount extends Asset {
	static typeName() { return 'Broker Account'; }

	get broker() { return this.content.broker; }
	get accountNumber() { return this.content.account_number; }
	get owner() { return this.content.owner; }

	set broker( v ) { this.content.broker = v; }
	set accountNumber( v ) { this.content.account_number = v; }
	set owner( v ) { this.content.owner = v; }

	generateSummary() {
		let lastDigits = this.accountNumber.slice( -4 );
		if ( lastDigits.length > 0 )
			return `${this.broker} account #${lastDigits}`;
		return `${this.broker} account`;
	}
}

const ASSET_MAPPING = {
	credit_card: CreditCard,
	savings_bank_account: SavingsBankAccount,
	loan: Loan,
	site_credentials: SiteCredentials,
	broker_account: BrokerAccount
};
