import { useCallback, useContext, useEffect, useState } from 'react';
import { ApiClient, AppState } from '../contexts';
import PlanComponent from '../components/plan-component';
import AssetsComponent from '../components/assets-component';
import AssetView from '../components/asset-view';
import InvitePeopleComponent from '../components/invite-people-component';

const TAB_COMPONENTS = [ PlanComponent, AssetsComponent, InvitePeopleComponent ];

export default function WorkspaceScreen() {
	const { state, setWorkspace, navigateTo } = useContext( AppState );
	const [ error, setError ] = useState( null );
	const [ chosenTab, setChosenTab ] = useState( 0 );
	const [ isLoading, setLoading ] = useState( true );
	const api = useContext( ApiClient );

	useEffect( () => {
		setLoading( true );

		async function load() {
			let workspace = await api.getWorkspace( state.pageParams.workspaceId );
			console.log( workspace );
			if ( !workspace ) {
				setError( 'Error loading workspace' );
				return;
			}

			setWorkspace( workspace );
		}

		load().finally( () => setLoading( false ) );
	}, [] );

	const handleGoToHome = useCallback( e => {
		e.preventDefault();
		navigateTo( 'home' );
	}, [ navigateTo ] );

	const handleViewPlan = useCallback( e => {
		e.preventDefault();
		setChosenTab( 0 );
	}, [ setChosenTab ] );

	const handleViewAssets = useCallback( e => {
		e.preventDefault();
		setChosenTab( 1 );
	}, [ setChosenTab ] );

	const handleInvitePeople = useCallback( e => {
		e.preventDefault();
		setChosenTab( 2 );
	}, [ setChosenTab ] );

	const ChosenView = TAB_COMPONENTS[ chosenTab ];

	if ( isLoading )
		return <div>Loading workspace...</div>;

	return <div>
		<AssetView />
		<nav>
			<a href="#" onClick={ handleGoToHome }>Go to home!</a>
			<br />
			<button onClick={ handleViewPlan }>View Plan</button>
			<button onClick={ handleViewAssets }>View Assets</button>
			<button onClick={ handleInvitePeople }>Invite People</button>
		</nav>
		{ error && <div style={ { color: 'red', fontWeight: 'bold' } }>{ error }</div> }
		<h1>{ state.workspace.name }</h1>
		<ChosenView />
	</div>;
}
