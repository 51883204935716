import { useCallback, useContext, useState } from 'react';
import { ApiClient, AppState } from '../contexts';

export default function LoginScreen() {
	let apiClient = useContext( ApiClient );
	let { setUser, navigateTo } = useContext( AppState );
	let [ error, setError ] = useState( null );

	let handleSignUp = useCallback( e => {
		e.preventDefault();
		navigateTo( 'signup' );
	}, [ navigateTo ] );

	let handleSubmit = useCallback( async e => {
		e.preventDefault();

		let button = document.getElementById( 'submit_button' );
		button.disabled = true;
		button.value = "Loading...";

		let email = document.getElementById( 'email' ).value;

		if ( await apiClient.login( email ) ) {
			setUser( { loggedIn: false, email } );
			navigateTo( 'login-mfa' );
		} else {
			button.value = 'Login';
			button.disabled = false;
			setError( 'There was an error' );
		}
	}, [ apiClient ] );

	return <form onSubmit={ handleSubmit }>
		<label htmlFor="email">Email:</label>
		<input id="email" type="email" />
		<br />

		<input id="submit_button" type="submit" value="Login" />

		<p>Don't have a user? <a href="#" onClick={ handleSignUp }>sign up</a>!</p>
		{ error && <div style={ { color: 'red', fontWeight: 'bold' } }>{ error }</div> }
	</form>;
}
