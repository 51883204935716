import React, { useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApiClient, AppStateProvider } from './contexts';
import { LyfePlanApi } from './api';
import { Crypto } from './crypto/crypto';

const api = new LyfePlanApi( 'https://protoapi.lyfeplan.amalgama.co' );

// For debugging
window.api = api;
window.myCrypto = Crypto;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <AppStateProvider>
        <ApiClient.Provider value={ api }>
          <App />
        </ApiClient.Provider>
     </AppStateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
