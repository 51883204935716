import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ApiClient, AppState } from '../contexts';

export default function AssetRenderer( { workspace, asset, onSave = undefined } ) {
	const { rerender, pushAsset } = useContext( AppState );
	const api = useContext( ApiClient );

	let keys = useMemo( () => Object.getOwnPropertyNames( asset.content ), [ asset ] );
	let names = useMemo( () =>
			keys.map( key => {
				let parts = key.split( '_' );
				parts[ 0 ] = parts[ 0 ][ 0 ].toUpperCase() + parts[ 0 ].slice( 1 )
				return parts.join( ' ' );
			} ),
		[ asset ] );

	let [ content, setContent ] = useState( asset.content );
	useEffect( () => setContent( asset.content ), [ asset ] );

	const handleInputChange = useCallback( e => {
		e.preventDefault();
		let key = e.target.dataset.assetKey
		let value = e.target.value;

		setContent( oldState => ( {
			...oldState,
			[ key ]: value
		} ) );
	}, [ setContent ] );

	const handleFormSubmit = useCallback( async e => {
		e.preventDefault();
		asset.updateContent( content );
		if ( asset.id ) {
			api.saveAsset( workspace, asset );
			rerender();
		} else {
			let response = await api.saveAsset( workspace, asset );
			if ( !response )
				return;
			asset.id = response._id;
			pushAsset( asset );
		}

		if ( onSave )
			onSave( asset );
	}, [ content ] );

	return <div>
		<h3>{ asset.summary }</h3>
		<form
			style={ {
				display: 'inline-grid',
				gridTemplateColumns: '1fr 1fr'
			} }
			onSubmit={ handleFormSubmit }>
			{
				keys.map( ( k, i ) => {
					return <>
						<label key={`label-${k}`}
						       style={ {
							       margin: '5px',
							       textAlign: 'right'
						       } }
						       htmlFor={ `asset-input-${k}` }>{ names[ i ] }:</label>
						<input key={`input-${k}`}
						       style={ {
							       margin: '5px',
							       padding: '5px'
						       } }
						       type="text"
						       id={ `asset-input-${k}` }
						       data-asset-key={ k }
						       value={ content[ k ] }
						       onChange={ handleInputChange }
						/>
					</>;
				} )
			}
			<span key="unused-span"/>
			<input key="submit-save" type="submit" value="Save" />
		</form>
	</div>;
}
