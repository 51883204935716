import { ApiClient, AppState } from '../contexts';
import { useCallback, useContext, useEffect, useState } from 'react';

export default function HomeScreen( callback, deps ) {
	const { state, setWorkspaces, navigateTo } = useContext( AppState );
	const [ error, setError ] = useState( null );
	const [ loading, setLoading ] = useState( false );
	const api = useContext( ApiClient );

	useEffect( () => {
		setLoading( true );
		api.getWorkspaces()
			.then( ws => setWorkspaces( ws ) )
			.catch( err => setError( "There was an error loading the workspaces" ) )
			.finally( () => setLoading( false ) );
	}, [] );

	const handleClick = useCallback( e => {
		e.preventDefault();
		let workspaceId = e.target.dataset.id;
		navigateTo( 'workspace', { workspaceId } );
	}, [ navigateTo ] );

	const handleWorkspaceCreation = useCallback( async e => {
		e.preventDefault();
		let name = document.getElementById( 'new-workspace' ).value;
		let workspace = await api.createWorkspace( name );
		if ( workspace )
			setWorkspaces( [ ...state.workspaces, workspace ] );
		else
			setError( "There was an error creating the workspace" );

		document.getElementById( 'new-workspace' ).value = '';
	} );

	if ( loading ) {
		return <div>Loading...</div>
	}

	return <div>
		{ error && <div style={ { color: 'red', fontWeight: 'bold' } }>{ error }</div> }
		Chose your workspace:
		<ul>
			{ state.workspaces.map( w => <li key={ w._id }>
					<a href="#" data-id={ w._id } onClick={ handleClick }>{ w.name }</a>
				</li> )
			}
		</ul>

		<form onSubmit={ handleWorkspaceCreation }>
			<label htmlFor="new-workspace">New Workspace Name:</label>
			<input type="text" id="new-workspace" />
			<input type="submit" value="Create Workspace!" />
		</form>
	</div>;
}
