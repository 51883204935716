import Asset from '../entities/asset';

class ApiResponse {
	constructor( response, data = undefined ) {
		this.response = response;
		this.data = data;
	}

	get isSuccessful() {
		return this.response.status === 200;
	}
}

class HttpApi {
	constructor( baseUrl ) {
		this.baseUrl = baseUrl;
		this.accessToken = null;
	}

	get isLoggedIn() { return !!this.accessToken; }

	get( path, json, parseResponse = true ) {
		return this.request( 'GET', path, json, parseResponse )
	}

	post( path, json, parseResponse = true ) {
		return this.request( 'POST', path, json, parseResponse )
	}

	patch( path, json, parseResponse = true ) {
		return this.request( 'PATCH', path, json, parseResponse )
	}

	async request( method, path, json, parseResponse = true ) {
		try {
			let headers = { 'Content-Type': 'application/json' };
			if ( this.accessToken )
				headers[ 'Authorization' ] = `Bearer ${ this.accessToken }`;

			let response = await fetch( `${ this.baseUrl }/${ path }`, {
				method,
				headers,
				body: JSON.stringify( json ),
			} );


			if ( parseResponse ) {
				let data = await response.json();
				return new ApiResponse( response, data );
			}

			return new ApiResponse( response );
		} catch ( e ) {
			return new ApiResponse( { status: 400 } );
		}
	}
}

export class LyfePlanApi {
	constructor( baseUrl ) {
		this.http = new HttpApi( baseUrl )
		this.keyPair = null;
	}

	get isLoggedIn() {
		return this.http.isLoggedIn;
	}

	async login( email ) {
		let response = await this.http.post( 'login', { email } );
		return response.isSuccessful;
	}

	async loginMfaStep( email, code ) {
		let response = await this.http.post( 'login-mfa', { email, code } );
		if ( response.isSuccessful ) {
			this.http.accessToken = response.data.response.access_token;
			return response.data.response.encryption_keys;
		}

		return null;
	}

	async signup( email, privateKey, publicKey ) {
		let response = await this.http.post( 'signup', {
			email, privateKey, publicKey
		} );
		if ( !response.isSuccessful )
			return null;

		return response.data.response;
	}

	async getWorkspaces() {
		let response = await this.http.get( 'workspaces' );
			return response.data.response;
		return [];
	}

	async getWorkspace( workspaceId ) {
		let response = await this.http.get( 'workspaces/' + workspaceId );
		if ( !response.isSuccessful )
			return null;

		let workspace = response.data.response;

		// Decrypt assets and plans
		workspace.encryptionKey = await this.keyPair.crypto.importSymmetricalKey(
			await this.keyPair.decrypt(
				workspace.members
					.find( m => m.encryptionKey )
					.encryptionKey ) );

		// Decrypt assets
		for ( let i = 0; i < workspace.assets.length; i++ ) {
			let asset = workspace.assets[ i ];
			if ( typeof asset.content === 'string' )
				asset.content = await workspace.encryptionKey.decrypt( asset.content );
			workspace.assets[ i ] = Asset.fromJson( asset );
		}

		// Decrypt plans
		for ( let i = 0; i < workspace.plans.length; i++ ) {
			let plan = workspace.plans[ i ];
			if ( typeof plan.content === 'string' )
				plan.content = await workspace.encryptionKey.decrypt( plan.content );
		}

		workspace.assetsByType = {};
		workspace.assets.forEach( asset => {
			if ( !(workspace.assetsByType[ asset.type ] instanceof Array) )
				workspace.assetsByType[ asset.type ] = [];
			workspace.assetsByType[ asset.type ].push( asset );
		} );

		workspace.assetsById = {};
		workspace.assets.forEach( asset => {
			workspace.assetsById[ asset.id ] = asset;
		} );

		return workspace;
	}

	async createWorkspace( name ) {
		if ( !this.keyPair )
			throw new Error( 'Invalid key pair' );

		let exportedKey = await this.keyPair.crypto.exportNewRandomSymmetricalKey();
		let encryptionKey = await this.keyPair.encrypt( exportedKey );

		let response = await this.http.post( 'workspaces', {
			name,
			encryptionKey
		} );

		if ( response.isSuccessful )
			return response.data.response;
		return null;
	}

	async saveAsset( workspace, asset ) {
		if ( !this.keyPair )
			throw new Error( 'Need a key pair' );

		let content = await workspace.encryptionKey.encrypt( asset.content )
		let encryptedAsset = {
			asset: {
				...asset,
				content
			}
		};

		let method = 'patch';
		let url = `workspaces/${workspace._id}/assets/${asset.id}`;
		if ( !asset.id ) {
			url = `workspaces/${ workspace._id }/assets`;
			method = 'post';
		}

		let response = await this.http[ method ]( url, encryptedAsset );
		if ( response.isSuccessful )
			return response.data.response;
		return null;
	}

	async savePlan( workspace, plan ) {
		if ( !this.keyPair )
			throw new Error( 'Need a key pair' );

		let content = await workspace.encryptionKey.encrypt( plan.content )
		let encryptedPlan = {
			plan: {
				...plan,
				content
			}
		};

		let url = `workspaces/${workspace._id}/plans/${plan._id}`;
		let response = await this.http.patch( url, encryptedPlan );
		if ( response.isSuccessful )
			return response.data.response;
		return null;
	}

	async invitablePeople( workspace ) {
		let response = await this.http.get( `workspaces/${workspace._id}/invitable_people` );
		if ( response.isSuccessful )
			return response.data.response;
		return [];
	}

	async inviteUser( workspace, user ) {
		let userPublicKey = await this.keyPair.crypto.importPublicKey( user.publicKey );
		let exportedKey = await workspace.encryptionKey.export();
		let encryptedKey = await this.keyPair.encryptWithKeyPair( exportedKey, { publicKey: userPublicKey } );

		let response = await this.http.post( `workspaces/${workspace._id}/invite`, {
			userId: user.id,
			encryptedKey
		} );

		return response.isSuccessful;
	}
}
