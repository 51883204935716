import { useCallback, useContext, useState } from 'react';
import { ApiClient, AppState } from '../contexts';
import { Crypto } from '../crypto/crypto';

export default function LoginMfaScreen() {
	let apiClient = useContext( ApiClient );
	let { state, setUser, navigateTo } = useContext( AppState );
	let [ error, setError ] = useState( null );
	let [ keys, setKeys ] = useState( null );

	let handleSubmit = useCallback( async e => {
		e.preventDefault();

		let button = document.getElementById( 'submit_button' );
		button.disabled = true;
		button.value = "Loading...";

		let code = document.getElementById( 'code' ).value;
		let password = document.getElementById( 'password' ).value;

		let encryptionKeys = keys;
		if ( !keys )
			encryptionKeys = await apiClient.loginMfaStep( state.user.email, code );

		if ( encryptionKeys ) {
			let crypto = new Crypto();
			try {
				apiClient.keyPair = await crypto.importKeyPair(
					password,
					encryptionKeys.private,
					encryptionKeys.public );
			} catch ( ex ) {
				setKeys( encryptionKeys );
				setError( 'Invalid password' );
				button.disabled = false;
				button.value = 'Login';
				return;
			}
			setUser( { loggedIn: true } );
			navigateTo( 'home' );
		} else {
			button.value = 'Login';
			button.disabled = false;
			setError( 'There was an error' );
		}
	}, [ keys, state, apiClient ] );

	return <form onSubmit={ handleSubmit }>
		<label htmlFor="code">Code:</label>
		<input id="code" type="text" />
		<br />

		<label htmlFor="password">Password:</label>
		<input id="password" type="password" />
		<br />

		<input id="submit_button" type="submit" value="Login" />

		{ error && <div style={ { color: 'red', fontWeight: 'bold' } }>{ error }</div> }
	</form>;
}
