import { base64ToBuffer, bufferToBase64 } from './utils';

export default class KeyPair {
	constructor( crypto, privateKey, publicKey ) {
		this.crypto = crypto;
		this.privateKey = privateKey;
		this.publicKey = publicKey;
	}

	/**
	 * Encrypts a string of text.
	 *
	 * It uses the Integrated Encryption Scheme.
	 *
	 * @param data {Object} A string of text or an object that will be converted to JSON
	 */
	async encrypt( data ) {
		let extraKeyPair = await crypto.subtle.generateKey(
			{ name: 'ECDH', namedCurve: this.crypto.namedCurve },
			true,
			[ 'deriveKey' ] );

		let symmetricKey = await crypto.subtle.deriveKey(
			{ name: 'ECDH', namedCurve: this.crypto.namedCurve, public: extraKeyPair.publicKey },
			this.privateKey,
			{ name: 'AES-GCM', length: this.crypto.aesKeyLength },
			false,
			[ 'encrypt' ] );

		let initializationVector = this.crypto.generateInitializationVector();
		let encodedData = new TextEncoder().encode( JSON.stringify( data ) );

		let encryptedData = await crypto.subtle.encrypt(
			{ name: 'AES-GCM', iv: initializationVector },
			symmetricKey,
			encodedData );

		let exportedPublicKey = JSON.stringify( await crypto.subtle.exportKey( 'jwk', extraKeyPair.publicKey ) );

		return [
			bufferToBase64( encryptedData ),
			bufferToBase64( initializationVector ),
			btoa( exportedPublicKey ) ].join( ',' );
	}

	async encryptWithKeyPair( data, extraKeyPair ) {
		let symmetricKey = await crypto.subtle.deriveKey(
			{ name: 'ECDH', namedCurve: this.crypto.namedCurve, public: extraKeyPair.publicKey },
			this.privateKey,
			{ name: 'AES-GCM', length: this.crypto.aesKeyLength },
			false,
			[ 'encrypt' ] );

		let initializationVector = this.crypto.generateInitializationVector();
		let encodedData = new TextEncoder().encode( JSON.stringify( data ) );

		let encryptedData = await crypto.subtle.encrypt(
			{ name: 'AES-GCM', iv: initializationVector },
			symmetricKey,
			encodedData );

		let exportedPublicKey = JSON.stringify( await crypto.subtle.exportKey( 'jwk', this.publicKey ) );

		return [
			bufferToBase64( encryptedData ),
			bufferToBase64( initializationVector ),
			btoa( exportedPublicKey ) ].join( ',' );
	}

	async decrypt( encryptedData ) {
		let [ rawData, iv, publicKey ] = encryptedData.split( ',' );

		rawData = base64ToBuffer( rawData );
		iv = base64ToBuffer( iv );

		publicKey = await this.crypto.importPublicKey( JSON.parse( atob( publicKey ) ) );

		let symmetricKey = await crypto.subtle.deriveKey(
			{ name: 'ECDH', namedCurve: this.crypto.namedCurve, public: publicKey },
			this.privateKey,
			{ name: 'AES-GCM', length: this.crypto.aesKeyLength },
			false,
			[ 'decrypt' ] );

		let decryptedRawData = await crypto.subtle.decrypt(
			{ name: 'AES-GCM', iv },
			symmetricKey,
			rawData );

		return JSON.parse( new TextDecoder().decode( decryptedRawData ) );
	}
}
