import { useCallback, useContext, useEffect, useState } from 'react';
import { ApiClient, AppState } from '../contexts';

export default function InvitePeopleComponent() {
	const api = useContext( ApiClient );
	const { state } = useContext( AppState );
	const [ loading, setLoading ] = useState( null );
	const [ people, setPeople ] = useState( [] );


	useEffect( () => {
		setLoading( true );
		(async function () {
			let people = await api.invitablePeople( state.workspace );
			setPeople( people );
			setLoading( false );
		})();
	}, [] )

	const handleClick = useCallback( async e => {
		e.preventDefault();
		e.target.innerHTML = 'Loading...';
		setLoading( true );
		let userId = e.target.dataset.userId;
		let user = people.find( u => u.id === userId );

		let isInvited = await api.inviteUser( state.workspace, user );
		if ( isInvited )
			setPeople( people.filter( u => u.id !== userId ) );

		e.target.innerHTML = 'Invite!';
		setLoading( false );
	}, [ setLoading, api, people, setPeople ] );

	return <div>
		{ loading && <p>Loading...</p> }
		<ul>
			{
				people.map( u =>
					<li key={ u.id }>
						{ u.email }
						-
						<a href="#" onClick={ handleClick } data-user-id={ u.id }>Invite!</a>
					</li> )
			}
		</ul>
	</div>
}
