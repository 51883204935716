import './App.css';
import { useContext } from 'react';
import { AppState } from './contexts';
import LoginScreen from './screens/login-screen';
import LoginMfaScreen from './screens/login-mfa-screen';
import HomeScreen from './screens/home-screen';
import WorkspaceScreen from './screens/workspace-screen';
import SignupScreen from './screens/signup-screen';

function App() {
  let { state } = useContext( AppState );

  if ( !state.user.loggedIn ) {
    switch ( state.page ) {
      case 'signup': return <SignupScreen />;
      case 'login': return <LoginScreen/>;
      case 'login-mfa': return <LoginMfaScreen/>;
      default: return <div>App state broken</div>;
    }
  } else {
    switch ( state.page ) {
      case 'home': return <HomeScreen/>;
      case 'workspace': return <WorkspaceScreen />;
      default: return <div>App state broken</div>;
    }
  }
}

export default App;
