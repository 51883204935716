import { useCallback, useContext, useState } from 'react';
import { ApiClient, AppState } from '../contexts';
import { Crypto } from '../crypto/crypto';

function createKeys( password ) {
	let crypto = new Crypto();
	return crypto.createKeyPair( password );
}

export default function SignupScreen() {
	const { navigateTo } = useContext( AppState );
	const api = useContext( ApiClient );
	const [ error, setError ] = useState( null );

	const handleSubmit = useCallback( async e => {
		e.preventDefault();
		let email = document.getElementById( 'email' ).value;
		let password1 = document.getElementById( 'password1' ).value;
		let password2 = document.getElementById( 'password2' ).value;

		if ( password1 !== password2 ) {
			setError( 'Passwords don\'t match' );
			return;
		}

		let keys = await createKeys( password1 );
		let user = await api.signup( email, keys.encryptedPrivateKey, keys.exportedPublicKey );
		if ( user )
			navigateTo( 'login' );
		else
			setError( 'There was an error, maybe the e-mail address was already taken' );
	}, [ api, navigateTo, setError ] );

	const handleLogin = useCallback( e => {
		e.preventDefault();
		navigateTo( 'login' );
	}, [ navigateTo ] );

	return <form onSubmit={ handleSubmit }>
		<label htmlFor="email">Email:</label>
		<input type="email" id="email" />
		<br />

		<label htmlFor="password1">Password:</label>
		<input type="password" id="password1" />
		<br />

		<label htmlFor="password2">Repeat Password:</label>
		<input type="password" id="password2" />
		<br />

		<input type="submit" value="Sign Up" id="signup_button" />

		<p>
			Already have a user? <a href="#" onClick={ handleLogin }>Login!</a>
		</p>

		{ error && <div style={ { color: 'red', fontWeight: 'bold' } }>
			{ error }
		</div> }
	</form>
}
