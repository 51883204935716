import { base64ToBuffer, bufferToBase64 } from './utils';

export default class SymmetricalKey {
	constructor( crypto, key ) {
		this.crypto = crypto;
		this.key = key;
	}

	async encrypt( data ) {
		let iv = this.crypto.generateInitializationVector();
		let encodedData = new TextEncoder().encode( JSON.stringify( data ) );

		let encryptedData = await crypto.subtle.encrypt(
			{ name: 'AES-GCM', iv },
			this.key,
			encodedData );

		return [ iv, encryptedData ].map( bufferToBase64 ).join( ',' );
	}

	async decrypt( data ) {
		let [ iv, encryptedData ] = data.split( ',' ).map( base64ToBuffer );
		let decryptedData = await crypto.subtle.decrypt(
			{ name: 'AES-GCM', iv },
			this.key,
			encryptedData )

		return JSON.parse( new TextDecoder().decode( decryptedData ) );
	}

	export() {
		return crypto.subtle.exportKey( 'jwk', this.key );
	}
}
