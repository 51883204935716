import { base64ToBuffer, bufferToBase64 } from './utils';
import KeyPair from './keypair';
import SymmetricalKey from './symmetrical-key';

function joinBinaries( ...binaries ) {
	return binaries.map( bufferToBase64 ).join( ',' );
}

function splitBinaries( binary ) {
	return binary.split( ',' ).map( base64ToBuffer );
}

export class Crypto {
	constructor( { namedCurve, pbkdf2Iterations, shaAlgorithm, aesKeyLength } = {} ) {
		this.namedCurve = namedCurve || 'P-521';
		this.pbkdf2Iterations = pbkdf2Iterations || 100000;
		this.shaAlgorithm = shaAlgorithm || 'SHA-256';
		this.aesKeyLength = aesKeyLength || 256;
	}

	async importKeyPair( password, encryptedPrivateKey, publicKey, extractable = true ) {
		let privateKey = await this.importPrivateKey( password, encryptedPrivateKey );
		publicKey = await this.importPublicKey( publicKey, extractable );

		return new KeyPair( this, privateKey, publicKey );
	}

	async importPrivateKey( password, encryptedPrivateKeyParts ) {
		let [ encryptedPrivateKey, salt, initializationVector ] = splitBinaries( encryptedPrivateKeyParts );
		let encryptionKey = await this.createCredentialEncryptionPassword( password, salt );
		return crypto.subtle.unwrapKey(
			'jwk',
			encryptedPrivateKey,
			encryptionKey.key,
			{ name: 'AES-GCM', iv: initializationVector },
			{ name: 'ECDH', namedCurve: this.namedCurve },
			false,
			[ 'deriveKey', 'deriveBits' ] );
	}

	importPublicKey( publicKey ) {
		return crypto.subtle.importKey(
			'jwk',
			publicKey,
			{ name: 'ECDH', namedCurve: this.namedCurve },
			true,
			[] );
	}

	async createSigningKeyPair( password ) {
		let keyPair = await crypto.subtle.generateKey(
			{ name: 'ECDSA', namedCurve: this.namedCurve },
			true,
			[ 'sign', 'verify' ] );
		return this.exportKeyPair( password, keyPair);
	}

	async createKeyPair( password ) {
		let keyPair = await crypto.subtle.generateKey(
			{ name: 'ECDH', namedCurve: this.namedCurve },
			true,
			[ 'deriveKey', 'deriveBits' ] );
		return this.exportKeyPair( password, keyPair );
	}

	async exportKeyPair( password, keyPair ) {
		let encryptionKey = await this.createCredentialEncryptionPassword( password );
		let initializationVector = await this.generateInitializationVector();
		let encryptedPrivateKey = await crypto.subtle.wrapKey(
			'jwk',
			keyPair.privateKey,
			encryptionKey.key,
			{
				name: 'AES-GCM',
				iv: initializationVector
			} );

		const exportedPublicKey = await crypto.subtle.exportKey( 'jwk', keyPair.publicKey )
		encryptedPrivateKey = joinBinaries( encryptedPrivateKey, encryptionKey.salt, initializationVector );

		return {
			publicKey: keyPair.publicKey,
			privateKey: keyPair.privateKey,
			encryptedPrivateKey,
			exportedPublicKey
		};
	}

	getRandomValues( length ) {
		let array = new Uint8Array( length );
		crypto.getRandomValues( array );
		return array;
	}

	async exportNewRandomSymmetricalKey() {
		let key = await this.generateRandomSymmetricKey()
		return crypto.subtle.exportKey( 'jwk', key )
	}

	async importSymmetricalKey( key, extractable = true ) {
		let importedKey = await crypto.subtle.importKey(
			'jwk',
			key,
			{ name: 'AES-GCM', length: this.aesKeyLength },
			extractable,
			[ 'encrypt', 'decrypt' ] );

		return new SymmetricalKey( this, importedKey );
	}

	async createCredentialEncryptionPassword( password, salt = undefined ) {
		let symmetricKey = await this.createSymmetricKey( password );

		if ( !salt )
			salt = this.getRandomValues( 16 );

		let key = await crypto.subtle.deriveKey(
			{
				name: 'PBKDF2',
				salt,
				iterations: this.pbkdf2Iterations,
				hash: this.shaAlgorithm
			},
			symmetricKey,
			{ name: 'AES-GCM', length: this.aesKeyLength },
			true,
			[ 'encrypt', 'decrypt', 'wrapKey', 'unwrapKey' ] );

		return {
			salt,
			key
		};
	}

	createSymmetricKey( password ) {
		let encodedPassword = new TextEncoder().encode( password );
		return crypto.subtle.importKey(
			'raw',
			encodedPassword,
			'PBKDF2',
			false,
			[ 'deriveBits', 'deriveKey' ]
		);
	}

	generateRandomSymmetricKey() {
		let randomPassword = this.getRandomValues( this.aesKeyLength / 8 );
		return crypto.subtle.importKey(
			'raw',
			randomPassword,
			{ name: 'AES-GCM', length: this.aesKeyLength },
			true,
			[ 'encrypt', 'decrypt' ]
		);
	}

	generateInitializationVector() {
		return this.getRandomValues( this.aesKeyLength / 8 );
	}
}
