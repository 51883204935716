import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ApiClient, AppState } from '../contexts';
import AssetRenderer from './asset-renderer';
import Asset from '../entities/asset';

function AssetForm( { workspace, type, onSave } ) {
	let [ asset, setAsset ] = useState( null );

	useEffect( () => {
		setAsset( Asset.allAssetTypes()[ type ].empty() );
	}, [ type ] );

	if ( !asset )
		return;
	return <AssetRenderer
		workspace={ workspace }
		asset={ asset }
		onSave={ onSave } />
}

function NewAsset( { workspace, onSave } ) {
	const [ assetType, setAssetType ] = useState( 'credit_card' );

	return <div>
		<label htmlFor="new-asset-type">Asset Type:</label>
		<select id="new-asset-type" onChange={ e => setAssetType( e.target.value ) } value={ assetType }>
			{
				Object.getOwnPropertyNames( Asset.allAssetTypes() ).map( code => {
					return <option
						key={ code }
						value={ code }>
						{ Asset.allAssetTypes()[ code ].typeName() }
					</option>;
				} )
			}
		</select>
		<AssetForm
			onSave={ onSave }
			workspace={ workspace }
			type={ assetType } />
	</div>
}

function AssetList( { workspace, selectAsset, onNewAsset } ) {
	const handleAssetSelection = useCallback( e => {
		e.preventDefault();
		let assetId = e.target.dataset.assetId;
		let asset = workspace.assetsById[ assetId ]
		if ( asset )
			selectAsset( asset );
	}, [ workspace, selectAsset ] );

	let assetList = Object.getOwnPropertyNames( workspace.assetsByType ).sort().map( assetType => {
		return <li key={ assetType }>{ workspace.assetsByType[ assetType ][ 0 ].typeName }
			<ul>
				{
					workspace.assetsByType[ assetType ].map( asset => <li key={ asset.id }>
						<a href="#" data-asset-id={ asset.id } onClick={ handleAssetSelection }>{ asset.summary }</a>
					</li> )
				}
			</ul>
		</li>
	} );

	const handleNewAsset = useCallback( e =>  {
		e.preventDefault();
		onNewAsset();
	} );

	return <>
		<div style={ { gridArea: 'h' } }>
			<h2 style={ { display: 'inline', marginRight: '16px' } }>Assets</h2>
			<button onClick={ handleNewAsset }>New Asset</button>
		</div>
		<ul>
			{ assetList }
		</ul>
	</>;
}

export default function AssetsComponent() {
	const { state } = useContext( AppState );
	const [ asset, setAsset ] = useState( null );
	const [ newAsset, setNewAsset ] = useState( false );

	const workspace = state.workspace;
	const selectAsset = useCallback( asset => setAsset( asset ), [ setAsset ] );

	return <div style={ { display: 'grid', gridTemplateAreas: '"h h h h" "l l f f"' } }>
		<AssetList workspace={ workspace } selectAsset={ selectAsset } onNewAsset={ () => setNewAsset( true ) } />
		{ newAsset
			? <NewAsset workspace={ workspace } onSave={ () => setNewAsset( false ) }/>
			: undefined }
		{ !newAsset && asset
			? <AssetRenderer workspace={ workspace } asset={ asset } />
			: undefined }
	</div>
}
