import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import LinkAutocomplete from '@editorjs/link-autocomplete';
import NestedList from '@editorjs/nested-list';
import Table from '@editorjs/table';
import CodeTool from '@editorjs/code';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import { useCallback, useEffect, useRef } from 'react';

class AssetTool extends LinkAutocomplete {
	/*
	constructor( params ) {
		super( params );
		this. = params.config;
	}
	 */
	async searchRequest( searchString ) {
		searchString = searchString.toLowerCase();
		return window.globalState.workspace.assets
			.filter( asset =>
				asset.summary.toLowerCase().includes( searchString ) ||
				asset.type.includes( searchString ) )
			.map( asset => ( {
				href: 'asset:' + asset.id,
				name: asset.summary
			} ) );
	}
}

export default function RichTextEditorComponent( { plan, onSave, onClickAsset } ) {
	const editor = useRef( null );

	const handleSave = useCallback( e => {
		e.preventDefault();
		if ( !editor.current || !editor.current.save )
			alert( 'Not ready to save!' );
		editor.current.save()
			.then( data => onSave( data ) )
			.catch( err => alert( 'Error saving data' ) );
	} );

	useEffect( () => {
		if ( editor.current && editor.current.render )
			editor.current.render( plan.content );
	}, [ plan ] );

	useEffect( () => {
		if ( editor.current )
			return;

		editor.current = new EditorJS( {
			holder: 'rich-text-editor',
			data: plan.content,
			tools: {
				header: Header,
				underline: Underline,
				list: {
					class: NestedList,
					inlineToolbar: true,
					config: {
						defaultStyle: 'unordered'
					}
				},
				link: {
					class: AssetTool,
					shortcut: 'CTRL+K'
				},
				table: {
					class: Table,
					inlineToolbar: true,
					config: {
						rows: 2,
						cols: 2
					}
				},
				code: CodeTool,
				marker: Marker,
			}
		} );

		return () => {
			if ( !editor.current )
				return;
			if ( editor.current.destroy ) {
				editor.current.destroy();
				editor.current = null;
			} else if ( editor.current.isReady ) {
				//editor.current.isReady
					//.then( () => editor.current.destroy() )
					//.finally( () => { editor.current = null; } );
			}
		}
	} );

	const handleClick = useCallback( e => {
		if ( !(e.target instanceof HTMLAnchorElement) || !e.target.href.startsWith( 'asset:' ) )
			return;
		e.preventDefault();
		e.stopPropagation();
		let assetId = e.target.href.slice( 'asset:'.length );
		onClickAsset( assetId );
	}, [ onClickAsset ] );

	return <>
		<button onClick={ handleSave }>Save Plan</button>
		<div id="rich-text-editor" onClick={ handleClick }>
		</div>
	</>;
}
